import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"
import WaveBottom from "@components/svg-decorations/waveBottom"

const AccountSupport = ({ location }) => (
  <Layout location={location}>
    <SEO
      title="Account Support"
      description="Get answers to your questions, request content, make alterations
      to your social posts, and more in one easy portal."
    />

    <div class="container-fluid headline-accntsup">
      <div class="container">
        <div class="row">
          <div class="col-md-6 text-white">
            <h1 class="mb-4">Leadroll Support</h1>
            <h6 class="mb-5">
              Get answers to your questions, request content, make alterations
              to your social posts, and more in one easy portal.
            </h6>
            <div class="row d-flex ml-3 mb-4">
              <div class="col-md-3">
                <h2>
                  <strong>Step&nbsp;</strong>
                  <span class="num-supp-1">1</span>&nbsp;
                </h2>
              </div>
              <div class="col-md-9">
                <span class="supp-desc">Choose a support request</span>
              </div>
            </div>
            <div class="row d-flex justify-content-lg-center ml-3 mb-4">
              <div class="col-md-3">
                <h2>
                  <strong>Step&nbsp;</strong>
                  <span class="num-supp-2">2</span>&nbsp;
                </h2>
              </div>
              <div class="col-md-9">
                <span class="supp-desc">
                  Fill out the form and provide as much direction as possible
                </span>
              </div>
            </div>
            <div class="row d-flex justify-content-center ml-3 mb-4">
              <div class="col-md-3">
                <h2>
                  <strong>Step&nbsp;</strong>
                  <span class="num-supp-3">3</span>&nbsp;
                </h2>
              </div>
              <div class="col-md-9">
                <span class="supp-desc">
                  We'll send a confirmation email and will begin working on your
                  request immediately
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-6 p-lg-5 mke-request-box">
            <div class="cta-container text-center">
              <h5 class="mb-4 text-purple">Choose From The Following To</h5>
              <h2 class="mb-5 text-purple">Make A Request</h2>
              <Link
                rel="noopener noreferrer"
                target="_blank"
                to="https://omnipresenceengine.com/support/socialmediamanagement"
                class="btn btn-primary d-block w-100 mb-4 btn-supp"
                type="button"
              >
                Request Social Post Support
              </Link>
              <Link
                rel="noopener noreferrer"
                target="_blank"
                to="https://omnipresenceengine.com/support/articlecontent"
                class="btn btn-primary d-block w-100 mb-4 btn-supp"
                type="button"
              >
                Request Article Support
              </Link>
              <Link
                rel="noopener noreferrer"
                target="_blank"
                to="https://omnipresenceengine.com/support/growthcampaign"
                class="btn btn-primary d-block w-100 mb-4 btn-supp"
                type="button"
              >
                Request Growth Campaign Support
              </Link>
            </div>
          </div>
        </div>
      </div>
      <WaveBottom />
    </div>
    <div class="container-fluid sub-section d-flex justify-content-center align-items-center">
      <div class="sub-section-container text-center text-white">
        <h2>Want to amplify your sales?</h2>
        <h6 class="mb-5 mt-3">Learn about LeadRoll below:</h6>
        <Link
          to="/faq"
          class="btn btn-primary btn-supp btn-sub-section"
          type="button"
        >
          FAQs
        </Link>
      </div>
    </div>
  </Layout>
)

export default AccountSupport
